import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import WroughtImg from '../../../assets/images/wrought-iron-gates.jpg'

class WroughtIronGates extends React.Component {
	render() {
		const siteTitle =
			'Wrought Iron Gate Installation - Arlington - Dallas - Fort Worth - J & J Gates'
		const siteDescription =
			'Do you need a wrought iron gate installed at your home? We Install wrought iron gates for homes in Arlington, Dallas, & Fort Worth. Call today for a custom gate design and Installation from J & J Gate Service & Design.'

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />

				<div className="container-fluid text-center headline gates-headline">
					<div className="container">
						<h1 className="text-white m-0">Wrought Iron Gates</h1>
						<hr />
					</div>
				</div>
				<div className="container d-padding-t d-padding-b access-sys">
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<p>
								Are you looking for a wrought iron gate installation for your
								home? Don’t trust just anyone with such an important project!
								Call for the experts at J & J Gates Service and Design. We
								proudly specialize in premier wrought iron gate installation and
								repair in <Link to="/locations/arlington-tx/">Arlington</Link>,{' '}
								<Link to="/locations/dallas-tx/">Dallas</Link>, and{' '}
								<Link to="/locations/fort-worth-tx/">Fort Worth</Link>.
							</p>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-8">
							<h4>The Advantages of a Wrought Iron Gate</h4>
							<p>
								When you want uncompromising beauty and high durability, wrought
								iron is your best choice.{' '}
								<strong>
									From basic designs to elaborate and ornate ones, wrought iron
									conveys a sense of elegance and old world style
								</strong>
								. Get some serious curb appeal and make your neighbors ooh and
								ahh over your new iron gate! J & J Gates Service and Design is
								happy to provide customized wrought iron gates to the DFW
								metroplex. Whether you need a gate for a residential or
								commercial property, our skilled crew will ensure that your
								needs are met. We want you to be completely satisfied with our
								service.
							</p>
							<p>
								Not only is wrought iron beautiful, it also provides reliable
								security.{' '}
								<strong>
									Plus, it is long-lasting and can increase the value of your
									property
								</strong>
								. If you’re looking to make an investment in a stunning wrought
								iron gate, talk to our team today about your options! We provide
								a wide variety of styles, including arched and bell tops, swing
								gates and slide gates, and more! Do you want an automated gate
								system? Our installers will connect your gate with any type of
								entry system you need, from remote control to keypad options.
							</p>
						</div>
						<div className="col-md-4 mt-5">
							<LazyLoadImage
								className="img-fluid mt-5"
								src={WroughtImg}
								alt="Wrought Iron Gate"
							/>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<h4>Make a Statement</h4>
							<p>
								From expert <Link to="/gates/gate-designs/">design</Link> to
								precise{' '}
								<Link to="/gates/services/gate-installation/">
									installation
								</Link>
								, we take every part of the job seriously. We know that you want
								a reliable, gorgeous gate that will not fail. This is especially
								important for homeowners who open and close their gates every
								single day. With J & J Gates Service and Design in DFW, you
								never have to worry about poor workmanship or poor service. Our
								staff is committed to your total satisfaction on every project.
								We even provide repairs, including to wrought iron gates that we
								did not install!{' '}
								<strong>
									<em>
										Call J & J Gates Service and Design at{' '}
										<a href="tel:8174662794">817-466-2794</a> for wrought iron
										gate installation in Arlington, Dallas, and Fort Worth, TX
									</em>
								</strong>
								.
							</p>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default WroughtIronGates

export const WroughtIronGatesPageQueryPageQuery = graphql`
	query WroughtIronGatesPageQueryPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
